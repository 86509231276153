<template>
  <ValidationObserver ref="observer" v-slot="{ valid }">
    <template>
      <div id="admin-window">
        <b-container fluid="sm">
          <div>
            <b-card header="">
              <div class="time-sheet-header" :style="dynamicStyle">
                <span class="time-sheet-text">Time Sheet Entry</span>
                <b-button
                  style="margin-top: -17px"
                  @click="downloadTimehseetByUserPopup"
                  type="button"
                  variant="primary"
                  >Download</b-button
                >
              </div>
              <hr />
              <TimeSheetBatched
                ref="batchedTimeSheet"
                :projects="projects"
                :batch="batch"
                :is-disabled="valid"
                :projectOwnerID="projectOwnerID"
                @reset-proj-filter="projFilter = 0"
                @saveData="saveData"
                @project-owner-id-changed="setProjectOwnerID"
              ></TimeSheetBatched>
            </b-card>
          </div>
        </b-container>
        <b-container fluid="sm">
          <!-- <button @click="downloadTableData">Download</button>
        <select class="form-control bars disableClass" ref="projectTypeFilter" style="width: 150px; margin-left: 28%; font-size: 12px; font-weight: bold;" @change="applyFilterForProject">
          <option :value=0>Project</option>
          <option :key="projectId" v-for="projectId in uniqueProjectIds" :value="projectId">
            {{ getProjectName(projectId) }}
          </option>
        </select> -->

          <Table
            class="custom-scrollbar"
            style="height: 70vh; font-size: 12px; overflow-x: hidden"
            filterable
            :range="range"
            @apply-filter="applyFilter"
            :is_processing="loading"
            :bus="bus"
          >
            <template #thead>
              <tr>
                <th
                  width="10%"
                  scope="col"
                  class="text-center"
                  @click="sortBy('activityDate')"
                >
                  Date
                  <i
                    v-if="sortKey === 'activityDate'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>
                <!-- <th style="border: 2px solid black;" width="10%" scope="col" class="text-center">Date</th> -->
                <th
                  width="20%"
                  scope="col"
                  class="text-center"
                  @click="sortBy('activity')"
                >
                  Activity
                  <i
                    v-if="sortKey === 'activity'"
                    class="fas fa-arrow-up"
                    :class="{ 'fa-rotate-180': sortDirection === -1 }"
                  ></i>
                </th>

                <th width="10%" scope="col" class="text-center">
                  Hours Worked
                </th>
                <th v-if="isOwnerVerified" width="10%" scope="col" class="text-center">
                  OT Hours
                </th>
                <th width="20%" scope="col" class="text-center">
                  <select
                    v-if="batch.projectId === 45"
                    class="form-control bars disableClass"
                    :value="projFilter"
                    ref="projectTypeFilterSub"
                    style="
                      width: 150px;
                      margin-left: 28%;
                      font-size: 12px;
                      font-weight: bold;
                    "
                    @change="applyFilterForProject"
                  >
                    <option :value="0">Project</option>
                    <option
                      :key="projects.subProjectId"
                      v-for="projects in uniqueSubProjectIds"
                      :value="JSON.stringify(projects)"
                    >
                      {{ getProjectName(projects.subProjectId) }}
                    </option>
                  </select>

                  <select
                    v-else
                    class="form-control bars disableClass"
                    ref="projectTypeFilter"
                    :value="projFilter"
                    style="
                      width: 150px;
                      margin-left: 28%;
                      font-size: 12px;
                      font-weight: bold;
                    "
                    @change="applyFilterForProject"
                  >
                    <option :value="0">Project</option>
                    <option
                      :key="projects.projectId"
                      v-for="projects in uniqueProjectIds"
                      :value="JSON.stringify(projects)"
                    >
                      {{ getProjectName(projects.projectId) }}
                    </option>
                  </select>
                </th>
                <!-- <th
                  v-if="batch.projectId === 45"
                  
                  width="10%"
                  scope="col"
                  class="text-center"
                >
                  Sub Project
                </th> -->
                <!-- <th style="border: 2px solid black;" width="5%" scope="col">Comment</th> -->
                <th width="10%" scope="col" class="text-center">Actions</th>
              </tr>
            </template>
            <template #tbody>
              <tr
                :key="timesheet.id"
                v-for="timesheet in timesheetsArr"
                style="border: 1px solid black"
              >
                <td style="border: 1px solid black" class="text-center">
                  {{ formatDate(timesheet.activityDate) }}
                </td>
                <td class="text-center" style="border: 1px solid black">
                  {{ timesheet.activity || "(empty)" }}
                </td>
                <td class="text-center" style="border: 1px solid black">
                  {{ timesheet.activityTime }}
                </td>
                <td v-if="isOwnerVerified" class="text-center" style="border: 1px solid black">
                  {{ timesheet.ot_hours }}
                </td>
                <td
                  style="border: 1px solid black"
                  class="text-center"
                  v-if="batch.projectId === 45"
                >
                  {{ getProjectName(timesheet.subProjectId) }}
                </td>
                <td style="border: 1px solid black" class="text-center" v-else>
                  {{ getProjectName(timesheet.projectId) }}
                </td>
                <!-- <td style="border: 2px solid black;" class="text-center">
                  <div v-if="timesheet.activityComment">
                    <i
                      :id="`popover-1-${timesheet.id}`"
                      class="fa fa-comment-alt"
                    ></i>

                    <b-popover
                      :target="`popover-1-${timesheet.id}`"
                      title="Comment"
                      triggers="hover focus"
                      :content="`${timesheet.activityComment}`"
                    ></b-popover>
                  </div>
                  <span v-else> - </span>
                </td> -->
                <td class="text-center" style="border: 1px solid black">
                  <div v-if="!timesheet.isApproved">
                    <b-icon
                      icon="pencil-fill"
                      v-on:click="() => editTimesheet(timesheet)"
                    ></b-icon>
                    &nbsp;
                    <b-icon
                      icon="trash"
                      aria-hidden="true"
                      v-on:click="() => showDeleteConfirmBox(timesheet)"
                    ></b-icon>
                  </div>
                  <div v-else>Approved</div>
                </td>
              </tr>
            </template>
            <template #tfoot>
              <tr>
                <th scope="col" colspan="2">Total</th>
                <!-- <td></td> -->
                <!-- <td colspan="0"></td> -->
                <th scope="col" class="text-center">{{ totalHours }}</th>
                <th  v-if="isOwnerVerified" scope="col" class="text-center">{{ totalOverTime }}</th>
                <th scope="col" class="text-center"></th>
                <th scope="col" class="text-center"></th>
                <!-- <th class="text-center">
                <button class="btn btn-success">Download</button>
              </th> -->
              </tr>
            </template>
          </Table>
        </b-container>
      </div>
    </template>
    <PopupOverlay
      :message="deleteMessage"
      v-if="timesheetToDelete"
      v-on:close="closePopup"
    >
    </PopupOverlay>

    <b-modal
      hide-footer
      centered
      title="Edit Timesheet"
      id="edit-time"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Activity Comments:</span>
            <b-input-group class="mt-3">
              <b-form-input
                id="Activity"
                v-model="selectedRecord.activity"
                type="text"
                :maxlength="50"
                name="Activity"
                placeholder="Add Task you performed"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="form-group col-12">
            <span class="float-left">Activity Date:</span>
            <b-input-group class="mt-3">
              <b-form-input
                id="ActivityDate"
                v-model="selectedRecord.activityDate"
                type="date"
                :maxlength="50"
                name="Activity"
                placeholder="Add Task you performed"
              ></b-form-input>
            </b-input-group>
          </div>

          <!-- <div class="form-group col-12">
            <span class="float-left">Activity Comment:</span>
            <b-input-group class="mt-3">
              <b-form-input
                id="ActivityComment"
                v-model="selectedRecord.activityComment"
                type="text"
                :maxlength="50"
                name="Activity"
                placeholder="Add Comment"
              ></b-form-input>
            </b-input-group>
          </div> -->
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Project:</span>
            <select class="form-control" v-model="selectedRecord.projectId">
              <option disabled selected style="display: none"></option>
              <option
                :key="index"
                v-for="(project, index) in projects.filter((project) => project.is_active)"
                :value="project.ProjectID"
              >
                {{ project.ProjectName }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row" v-if="selectedRecord.projectId === 45">
          <div class="form-group col-12">
            <span class="float-left">Sub Project:</span>
            <select class="form-control" v-model="selectedRecord.subProjectId">
              <option disabled selected style="display: none"></option>
              <option
                :key="index"
                v-for="(project, index) in filteredProjects.filter((project) => project.is_active)"
                :value="project.ProjectID"
              >
                {{ project.ProjectName }}
              </option>
            </select>
          </div>
        </div>

        <span class="float-left">Hours:</span>
        <b-form-input
          v-model="selectedRecord.activityTime"
          type="number"
          step="0.5"
          placeholder="Add Hours"
        ></b-form-input>
        
      <div v-if="isOwnerVerified">
          <span class="float-left">OT Hours:</span>
          <b-form-input
            v-model="selectedRecord.ot_hours"
            type="number"
            step="0.5"
            placeholder="Add Hours"
          ></b-form-input>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button variant="outline-danger" @click="$bvModal.hide('edit-time')"
          >Close</b-button
        >
        <b-button
          class="ml-3"
          variant="outline-primary"
          @click="updateTimesheet"
          :disabled="loading"
          >Save Changes</b-button
        >
      </div>
    </b-modal>

    <b-modal
      hide-footer
      centered
      title="Download Timesheet"
      id="timsheet-download"
      body-class="p-0"
    >
      <div class="d-block text-center p-3">
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Date:</span>
            <select
              class="form-control"
              v-model="dateChange"
              ref="userSelectedTimesheetProject"
            >
              <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(period, index) in periods"
                :value="period.key"
              >
                {{ period.value }}
              </option>
            </select>
            <span v-if="validationByUser2" class="float-left" style="color: red"
              >Date is Required</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Project:</span>
            <select
              class="form-control"
              v-model="projectChange"
              ref="userSelectedTimesheetProject"
            >
            <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(project, index) in projects2"
                :value="project"
              >
                {{ project.projectName }}
              </option>

            </select>
            <span v-if="validationByUser" class="float-left" style="color: red"
              >Project is Required</span
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <span class="float-left">Timesheet Type:</span>
            <select class="form-control" ref="userSelectedBillableType">
              <option :value="null" style="display: none">
                Please choose...
              </option>
              <option value="false" selected>Client Timesheet</option>
              <option
                :key="index"
                v-for="(project, index) in billableProject"
                :value="project.isBillable"
              >
                Insight-AWP Non Billable
              </option>
            </select>
            <!-- <span v-if="validationByUser" class="float-left" style="color: red"
              >Type is Required</span
            > -->
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button
          variant="outline-danger"
          @click="$bvModal.hide('timsheet-download')"
          >Close</b-button
        >
        <b-button
          class="ml-3"
          variant="outline-primary"
          :disabled="loading"
          @click="downloadTimehseetByUser"
          >Download</b-button
        >
      </div>
    </b-modal>

    <b-modal hide-footer hide-header centered id="export-pbi" body-class="p-0">
      <div>
        <section id="container"></section>
      </div>
      <!-- <hr class="m-0" />
        <div class="p-3">
          <b-button variant="outline-danger" @click="$bvModal.hide('edit-time')"
            >Close</b-button
          >
          <b-button class="ml-3" variant="outline-primary"
            >Save Changes</b-button
          >
        </div> -->
    </b-modal>
  </ValidationObserver>
</template>

<script>
// External libraries and components imported
import * as pbi from "powerbi-client";
import TimeSheetData from "../core/TimeSheetData";
import TimeSheetBatch from "../core/TimeSheetBatch";
import TimeSheetBatched from "../components/TimeSheetBatched";
import FormatUtilities from "../utilities/FormatUtilities";
import PopupOverlay from "../components/PopupOverlay";
import { extractError } from "../utilities/ErrorUtilities";
import Vue from "vue";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Table from "@/components/Table.vue";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import { mapGetters } from "vuex";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import { param } from "jquery";

Vue.use(VueSweetalert2);

extend("required", {
  ...required,
  message: "{_field_} must have a value"
});

export default {
  // imported components registered
  components: {
    TimeSheetBatched,
    PopupOverlay,
    Table
  },

  props: {
    loading: {
      default: false
    }
  },

  // data initialization
  data() {
    return {
      accessToken: "",
      timesheets: [],
      periods: [],
      projects: [],
      projects2: [],
      filterProjects: [],
      billableProject: [],
      projectChange: null,
      dateChange: null,
      selectedRecord: {},
      batch: new TimeSheetBatch(),
      selectedTimesheet: new TimeSheetData(),
      timesheetToDelete: null,
      ProjectID: "",
      isEditingBatch: true,
      range: {
        start: this.$moment().startOf("month").format("YYYY-MM-DD"),
        end: this.$moment().endOf("month").format("YYYY-MM-DD")
      },
      filter: {},
      sortKey: "",
      sortDirection: 1,
      validationByUser: false,
      validationByUser2: false, // 1 for ascending, -1 for descending
      projFilter: 0,
      bus: new Vue(),
      projectOwnerID: 0, //default for Project Owner sent from parent, when selecting project to add timesheet for
      isOwnerVerified: false, //used to see if it is a sighstream related, if itis allows the OT fields to show
      timesheet_projects: [] //used to get a list of district projects for a specific timesheet batch
    };
  },
  computed: {
    uniqueProjectIds() {
      const uniqueProjectsMap = new Map(); // Use a Map to store unique projects based on their IDs
      // console.log(this.filterProjects);

      this.filterProjects.forEach((project) => {
        // Assuming each project has a unique identifier named 'projectId'
        uniqueProjectsMap.set(project.projectId, project); // Store the project in the Map using its ID as the key
      });

      // Converting the Map values (unique projects) back to an array
      const uniqueProjectsArray = Array.from(uniqueProjectsMap.values());
      // console.log(uniqueProjectsArray);
      return uniqueProjectsArray;
    },
    uniqueSubProjectIds() {
      const uniqueProjectsMap = new Map(); // Use a Map to store unique projects based on their IDs
      // console.log(this.filterProjects);

      this.filterProjects.forEach((project) => {
        // Assuming each project has a unique identifier named 'projectId'
        uniqueProjectsMap.set(project.subProjectId, project); // Store the project in the Map using its ID as the key
      });

      // Converting the Map values (unique projects) back to an array
      const uniqueProjectsArray = Array.from(uniqueProjectsMap.values());
      // console.log(uniqueProjectsArray);
      return uniqueProjectsArray;
    },
    filteredProjects() {
      // Filter the projects array to exclude the project with Project ID 4
      return this.projects.filter((project) => project.ProjectID !== 45);
    },
    totalHours() {
      return this.timesheets
        .map((timesheet) => timesheet.activityTime)
        .reduce((total, number) => total + number, 0);
    },
    totalOverTime() {
      return this.timesheets
        .map((timesheet) => timesheet.ot_hours)
        .reduce((total, number) => total + number, 0);
    },

    deleteMessage() {
      if (!this.timesheetToDelete) {
        return ``;
      }
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      let date = this.timesheetToDelete.activityDate.substring(
        0,
        this.timesheetToDelete.activityDate.indexOf("T")
      );
      return `Are you sure you want to delete time from  ${this.timesheetToDelete.projectName} on ${date}`;
    },
    makeCharBold(str) {
      console.log(str);
      return "abcd";
    },
    timesheetsArr() {
      if (!this.timesheets.length) {
        return [];
      }
      var _cloned = this.$_.cloneDeep(this.timesheets);
      return _cloned.sort(
        (a, b) => new Date(b.activityDate) - new Date(a.activityDate)
      );
    },
    ...mapGetters({
      currentUser: "getLoggedInUser"
    }),
    dynamicStyle() {
      return {
        "--primary-color":
          this.currentUser && this.currentUser.userType === "sightstream"
            ? "#7393B3"
            : "#72a02e"
      };
    }
  },

  mounted() {
    this.resetPeriods();
    this.getFilteredProjects();
    this.verifyOwner();
  },

  // All the methods defined for this component
  methods: {
    formatDate: FormatUtilities.formatDate,
    formatPrice: FormatUtilities.formatPrice,
    getProjectName(id) {
      var project = this.projects.find((p) => p.ProjectID === id);
      if (!project) {
         project = this.timesheet_projects.find((p) => p.ProjectID === id);
        if (!project) {
          return "-";
        }
       return project.ProjectName;
      }
      return project.ProjectName;
    },
    setProjectOwnerID(projectOwnerID) {
      this.projectOwnerID = projectOwnerID;
    },
    sortBy(key) {
      if (key === this.sortKey) {
        this.sortDirection = -this.sortDirection; // toggle direction
      } else {
        this.sortKey = key;
        this.sortDirection = 1; // set default direction
      }

      // sort the data by the selected key and direction
      this.timesheetsArr.sort((a, b) => {
        let x = a[key];
        let y = b[key];

        if (typeof x === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        return this.sortDirection * ((x > y) - (y > x));
      });
    },
    getUppercaseValue(value) {
      if (typeof value === "string") {
        return value.toUpperCase();
      }
      return String(value).toUpperCase();
    },
    closePopup(okClicked) {
      if (okClicked) {
        this.deleteTimeSheet(this.timesheetToDelete);
      }
      this.timesheetToDelete = null;
    },
    downloadTimehseetByUserPopup() {
      this.projectChange = null;
      this.dateChange = null;
      this.projects2 = [];
      this.billableProject = [];
      this.$bvModal.show("timsheet-download");
    },
    verifyOwner() {
      //console.log("isnide verifyOwner...");
      //checks if any of the active projects they can bill hours to has a sightstream woner or not
      const activeProjects = this.projects.filter(proj => proj.is_active === 1);
      console.log("active projects...", activeProjects);
      this.isOwnerVerified = activeProjects.some(proj => proj.ProjectOwnerID === 2);
      console.log("result of verifyOwner...", this.isOwnerVerified);


      //checks if the usr is a sightsream user or not
      //console.log("result of verifyOwner..." ,this.currentUser && this.currentUser.userType === "sightstream");
      //this.isOwnerVerified = this.currentUser && this.currentUser.userType === "sightstream";
    },
    async getProjectsByUser() {
      try {
        const res = await this.$axios.get(
          `/projectsByUser?sort_by=ProjectName`,
          {
            params: {
              period: this.dateChange
            }
          }
        );
        if (res && res.data.length > 0) {
          this.projects2 = res.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getBillableByUsers() {
      try {
        const res = await this.$axios.get(`/billableUsers`, {
          params: {
            period: this.dateChange,
            project: this.projectChange.projectName
          }
        });
        if (res && res.data.length > 0) {
          this.billableProject = res.data;
          console.log(this.billableProject);
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getFilteredProjects() {
      try {
        const res = await this.$axios.get(`/getFilteredProjects`, {
          params: { ...this.range }
        });
        if (res && res.data.length > 0) {
          this.filterProjects = res.data;
          console.log(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    },

    async downloadTimehseetByUser() {
      let monthType = "";
      console.log(this.$refs.userSelectedBillableType.value);
      if (this.$refs.userSelectedBillableType.value === "false") {
        console.log("client");
        if (this.dateChange === "" || this.dateChange === null) {
          this.validationByUser2 = true;
        } else if (this.projectChange === "" || this.projectChange === null) {
          this.validationByUser = true;
        } else {
          this.$bvModal.hide("timsheet-download");
            let user = localStorage.getItem("user");
            user = JSON.parse(user);
            const [year, month] = this.dateChange.split("-");
            const dateObj = new Date(year, month);
            const options = { month: "long", year: "numeric" };
            let monthType = "";

            if (this.projectChange.BillingCycle === "Fixed Offset (26 to 25)") {
              monthType = "26 Prev - 25 Curr Month";
            } else if (this.projectChange.BillingCycle === "Standard Month (First to last)") {
              monthType = "Standard Month";
            } else {
              monthType = "Week Starting Saturday";
            }

            let embedUrl = "";
            let reportId = "";

            if (user.userType === "sightstream") {
              reportId = "f5139316-e9b5-45b9-9347-6c6495eb4498";
              let projectOwner = "Sightstream";

              if (this.projectChange.projectName === "PS - Project Moat" || this.projectChange.projectName === "PS - Wolf Hollow") {
                projectOwner = "Insight-AWP";
              }

              embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=${reportId}&rp:parmProjectOwner=${projectOwner}&rp:parmProjects=${this.projectChange.projectName}&rp:parmDate=${dateObj.toLocaleDateString("en-US", options)}&rp:parmUser=${user.name}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
            } else {
              reportId = "26b22f86-1e72-4430-abfe-9e7dbd4852b2";
              embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=${reportId}&rp:parmProjects=${this.projectChange.projectName}&rp:parmDate=${dateObj.toLocaleDateString("en-US", options)}&rp:parmDatesType=${monthType}&rp:parmUser=${user.name}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
            }

            this.$bvModal.show("export-pbi");
            this.setLoading(true);
            await this.$axios
              .post("/getPBIEmbed", {
                reportId: reportId
              })
              .then((response) => {
                console.log(response.data);
                this.setLoading(false);
                this.accessToken = response.data.body.token;
                const permissions = pbi.models.Permissions.All;

                const config = {
                  type: "report",
                  tokenType: pbi.models.TokenType.Embed,
                  accessToken: this.accessToken,
                  embedUrl: embedUrl,
                  settings: {
                    panes: {
                      filters: {
                        visible: false
                      },
                      pageNavigation: {
                        visible: false
                      }
                    }
                  },
                  permissions: permissions
                };

                let powerbi = new pbi.service.Service(
                  pbi.factories.hpmFactory,
                  pbi.factories.wpmpFactory,
                  pbi.factories.routerFactory
                );

                const dashboardContainer = document.getElementById("container");
                const dashboard = powerbi.embed(dashboardContainer, config);
                const container = document.getElementById("container");
                const iframe = container.querySelector("iframe");

                iframe.onload = function () {
                  const iframeDocument =
                    iframe.contentDocument || iframe.contentWindow.document;
                  console.log(iframeDocument); // Access the #document of the iframe here

                  // Perform any CSS manipulation or other operations on the iframeDocument
                };
                dashboard.on("loaded", function () {
                  console.log("fjhewjhufe");
                  const elementToHide =
                    dashboardContainer.contentDocument.querySelector("#root");
                  console.log(elementToHide);
                  if (elementToHide) {
                    elementToHide.style.display = "none";
                  }
                });
              });
          }
      } else {
        if (this.dateChange === "" || this.dateChange === null) {
          this.validationByUser2 = true;
        } else if (this.projectChange === "" || this.projectChange === null) {
          this.validationByUser = true;
        } else {
          this.$bvModal.hide("timsheet-download");
          let user = localStorage.getItem("user");
          user = JSON.parse(user);
          const [year, month] = this.dateChange.split("-");
          const dateObj = new Date(year, month);
          const options = { month: "long", year: "numeric" };
          let embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=1a9094a2-a146-4ae2-b05f-50a2664239b3&rp:parmProjects=${
            this.projectChange.projectName
          }&rp:parmDate=${dateObj.toLocaleDateString(
            "en-US",
            options
          )}&rp:parmUser=${
            user.name
          }&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
          this.$bvModal.show("export-pbi");
          this.setLoading(true);
          await this.$axios
            .post("/getPBIEmbed", {
              reportId: "1a9094a2-a146-4ae2-b05f-50a2664239b3"
            })
            .then((response) => {
              console.log(response.data);
              this.setLoading(false);
              this.accessToken = response.data.body.token;
              const permissions = pbi.models.Permissions.All;

              const config = {
                type: "report",
                tokenType: pbi.models.TokenType.Embed,
                accessToken: this.accessToken,
                embedUrl: embedUrl,
                settings: {
                  panes: {
                    filters: {
                      visible: false
                    },
                    pageNavigation: {
                      visible: false
                    }
                  }
                },
                permissions: permissions
              };

              let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
              );

              const dashboardContainer = document.getElementById("container");
              const dashboard = powerbi.embed(dashboardContainer, config);

              dashboard.off("loaded");
              dashboard.off("rendered");
              dashboard.on("error", function () {
                this.dashboard.off("error");
              });
            });
        }
      }
    },

    resetPeriods() {
      this.periods = [];

      try {
        let max_months = 2; // Display previous 2 months

        let current_month = this.$moment().startOf("month");
        this.periods.push({
          key: `${current_month.year()}-${current_month.month()}`,
          value: current_month.format("MMMM Y")
        });

        for (let month = 1; month <= max_months; month++) {
          let _month_year = this.$moment().subtract(month, "M");

          this.periods.push({
            key: `${_month_year.year()}-${_month_year.month()}`,
            value: _month_year.format("MMMM Y")
          });
        }
      } catch (err) {
        this.makeToast("danger", extractError(err));
      }
    },

    showDeleteConfirmBox(timeSheet) {
      this.timesheetToDelete = timeSheet;
    },

    isTimeSheetSelected(row) {
      return this.selectedTimesheet && this.selectedTimesheet.id === row.id;
    },

    rowClicked(row) {
      if (this.selectedTimesheet.id == row.id) {
        return (this.selectedTimesheet = {});
      }
      this.selectedTimesheet = row;
    },

    async getTimesheets() {
      this.getFilteredProjects();
      try {
        // console.log(this.batch.projectId);
        let projectID = "";
        let projectInfo = "";
        try {
          projectID = this.$refs.projectTypeFilter.value;
          projectInfo = this.$refs.projectTypeFilter.value;
        } catch (err) {
          projectID = this.$refs.projectTypeFilterSub.value;
          projectInfo = this.$refs.projectTypeFilterSub.value;
        }
        //console.log(projectID);
        // projectInfo = JSON.parse(projectInfo);
        if (projectID === null || projectID === "") {
          projectID = 0;
        } else {
          projectID = JSON.parse(projectID);
        }
        if (projectID === 0) {
          projectID = 0;
        } else if (this.batch.projectId === 45) {
          projectID = projectID.subProjectId;
        } else {
          projectID = projectID.projectId;
        }
        this.setLoading(true);
        var url = `/timesheets`;
        const timeSheets = await this.$axios.get(url, {
          params: {
            ...this.range,
            projectID: projectID,
            sortDirection: this.sortDirection,
            projectInfo: projectInfo,
            mainProject: this.batch.projectId
          }
        });
         console.log(timeSheets.data);

        if (timeSheets) {
          this.timesheets = timeSheets.data.map((d) =>
            TimeSheetData.fromApi(d)
          );
          // Extract distinct projects and update timesheet_projects array
        const uniqueProjectsMap = new Map();
        timeSheets.data.forEach((timesheet) => {
          uniqueProjectsMap.set(timesheet.ProjectID, timesheet.ProjectName);
        });
          this.timesheet_projects = Array.from(uniqueProjectsMap, ([ProjectID, ProjectName]) => ({ ProjectID, ProjectName }));
        }
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },

    async getProjects() {
      try {
        const res = await this.$axios.get(
          `/projectsByRoster?sort_by=ProjectName`
        );
        if (res && res.data.length > 0) {
          this.projects = res.data;
        }
        this.verifyOwner();
        console.log("projects....",this.projects);
      } catch (err) {
        console.error(err);
      }
    },

    async deleteTimeSheet(timeSheet) {
      try {
        this.setLoading(true);
        await this.$axios.delete(`/timesheets/${timeSheet.id}`);
        this.getTimesheets();
      } catch (err) {
        console.error(err);
      } finally {
        this.setLoading(false);
      }
    },

    editTimesheet(record) {
       console.log(record);
      const activityDate = record.activityDate.substring(
        0,
        record.activityDate.indexOf("T")
      ); // Extract the characters before the "T" character
      this.selectedRecord = Object.assign({}, record, { activityDate });
      // console.log(this.selectedRecord.activityDate.substring(0, this.selectedRecord.activityDate.indexOf("T")))

      //if project is Insight(CAD) it will get the subproject id 
      const projectIdToUse = record.projectId === 45 ? record.subProjectId : record.projectId;

      // Find the project in the projects array
      const project = this.projects.find(proj => proj.projectId === projectIdToUse);

      let projectOwnerID = null;
      if (project) {
        projectOwnerID = project.ProjectOwnerID;
      }

      // Add ProjectOwnerID to the selectedRecord
      this.selectedRecord = Object.assign({}, record, { activityDate, projectOwnerID });

      console.log(this.selectedRecord);
      this.$bvModal.show("edit-time");
    },

    displayError(err) {
      this.makeToast("danger", extractError(err));
    },

    async updateTimesheet() {
      // console.log(this.timesheetsArr);
      // from timesheet array get the all tthe data of the timsheet as per this.selectedRecord.id
      let user = JSON.parse(localStorage.getItem("user"));
      let timeSheet = this.timesheetsArr.find(
        (t) => t.id === this.selectedRecord.id
      );
      let data = this.$_.pick(this.selectedRecord, [
        "projectId",
        "subProjectId",
        "activityTime",
        "ot_hours",
        "activityComment",
        "activityDate",
        "activity"
      ]);
      data.previousData = timeSheet;

      if (data.projectId !== 45) {
        data.subProjectId = 0;
      }
      data.user = user;
       // Check if the selected date is within the current week
      const selectedDate = this.$moment(data.activityDate);
      const startOfWeek = this.$moment().utc().startOf("week").add(1, "days");
      console.log(selectedDate, startOfWeek);
      if (selectedDate.isBefore(startOfWeek)) {
        const result = await this.$swal({
          title: "The selected date is not within the current week.",
          text: "If you wish to proceed, an email will be sent to admins.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel"
        });
        if (!result.value) {
          return;
        }
      }
      console.log("data being updteed....", data);

      this.setLoading(true);
      try {
        await this.$axios.put(`/timesheets/${this.selectedRecord.id}`, data);
        const index = this.timesheets.findIndex(
          (t) => t.id === this.selectedRecord.id
        );
        this.getTimesheets();
        // if (index >= 0) {
        //   this.$set(this.timesheets, index, {
        //     ...this.timesheets[index],
        //     ...this.selectedRecord
        //   });
        // }
        this.makeToast("success", "Timesheet has been updated!");

        this.$refs.observer.reset();
        this.$bvModal.hide("edit-time");
      } catch (err) {
        this.displayError(
          err?.data?.message ||
            "Failed to update timesheet record, please try later."
        );
      } finally {
        this.setLoading(false);
      }
    },
    async saveData() {
      let timeSheets = [];
      if (this.isEditingBatch) {
        timeSheets = this.batch.toTimeSheets();
      } else {
        timeSheets = [this.selectedTimesheet];
        console.log("not editing timesheets", timeSheets);
      }
      console.log("project owner id...", this.projectOwnerID);
      console.log("timesheets from child....", timeSheets);
      timeSheets = timeSheets.map((obj) => this.stripDate(obj));
     

      const selectedDate = this.$moment(timeSheets[0].activityDate);
      const startOfWeek = this.$moment().utc().startOf("week").add(1, "days");
      console.log(selectedDate, startOfWeek);
      if (selectedDate.isBefore(startOfWeek)) {
        const result = await this.$swal({
          title: "The selected date is not within the current week.",
          text: "If you wish to proceed, an email will be sent to admins",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel"
        });
        if (!result.value) {
          return;
        }
      }
      this.setLoading(true);

      try {
        var timeSheetResp = await this.$axios.post(`/timesheets`, {
          timeSheets,
          user: JSON.parse(localStorage.getItem("user"))
        });
      } catch (err) {
        let data = err && err.data;
        if (data && data.message) {
          return this.displayError(data.message);
        } else {
          this.displayError(
            "Failed to save timesheet record, please try later."
          );
        }
      } finally {
        this.setLoading(false);
      }

      const _timeSheets = timeSheetResp.data.rows.map((d) =>
        TimeSheetData.fromApi(d)
      );

      _timeSheets.forEach((timesheet) => {
        const timeSheetIndex = this.timesheets.findIndex(
          (t) => t.id === timesheet.id
        );
        if (timeSheetIndex < 0) {
          this.timesheets.push(timesheet);
          // return this.$set(this, "timesheets", this.timesheets);
        }
        this.timesheets.splice(timeSheetIndex, 1, timesheet);
      });

      this.selectedTimesheet = new TimeSheetData();
      //this.batch = new TimeSheetBatch();
      this.makeToast("success", "Timesheet has been saved!");
      this.getFilteredProjects();
      this.$refs.observer.reset();
    },
    stripDate(obj) {
      return {
        ...obj,
        activityDate: this.$moment(obj.activityDate).format("YYYY-MM-DD")
      };
    },
    async applyFilter(range) {
      this.range = range;

      //this.$set(this, "projFilter", 0);

      // await this.getFilteredProjects();
      await this.getTimesheets();
      // await this.getFilteredProjects();
      if (!this.projects.length) {
        await this.getProjects();
      }
    },
    async applyFilterForProject(proj) {
      const selectedFilter = JSON.parse(proj.target.value);
      console.log(selectedFilter);

      if (selectedFilter === 0) {
        this.$set(this, "projFilter", 0);
        await this.applyFilter({
          start: this.$moment().startOf("month").format("YYYY-MM-DD"),
          end: this.$moment().endOf("month").format("YYYY-MM-DD")
        });
        return;
      }

      const billingCycle =
        this.batch.projectId === 45
          ? this.projects.filter(
              (p) => p.ProjectID === selectedFilter.subProjectId
            )[0].BillingCycle
          : selectedFilter.BillingCycle;

      this.$set(this, "projFilter", proj.target.value);

      //this.batch.projectId = 0;
      //await this.getTimesheets();

      var range = null;

      if (billingCycle == "Fixed Offset (26 to 25)") {
        // set start date to 26th of previous month
        var start = this.$moment().subtract(1, "months").date(26).toDate();
        // set end date to 25th of current month
        var end = this.$moment().date(25).toDate();

        range = { start, end };
      } else if (billingCycle == "Standard Month (First to last)") {
        range = {
          start: this.$moment().startOf("month").toDate(),
          end: this.$moment().endOf("month").toDate()
        };
      }

      console.log(range);

      range && (await this.applyFilter(range));

      //this.bus.$emit("billingCycle", billingCycle);
    }



  },
  watch: {
    projectChange: {
      handler: function (updatedProject) {
        this.validationByUser = false;
        this.getBillableByUsers();
      }
    },
    dateChange: {
      handler: function (updatedProject) {
        this.validationByUser2 = false;
        this.getProjectsByUser();
      }
    }
  }
};
</script>

<style>
/* table {
  background-color: white;
} */
thead {
  position: sticky;
  top: 0;
  background-color: white;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
</style>

<style>
.time-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.time-sheet-text {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
  font-size: 25px;
}

#container {
  width: 21cm; /* Adjust the width as needed */
  height: 30cm; /* Adjust the height as needed */
  border: 2.5px solid black;
}

@media only screen and (max-width: 1700px) {
  #container {
    height: 42vw;
  }
}
</style>
