export const min_number ={
  validate(value, args){
    const number = parseFloat(value);
    if(number !== NaN){
      return number > args.min;
    }
    return true;
  },
  params: ['min'],
  message: '{_field_} must be greater than 0'
}
export const whole_number = {
  validate(value) {
    const number = parseFloat(value);
    return Number.isInteger(number);
  },
  message: '{_field_} must be a whole number'
};