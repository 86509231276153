<template>
  <div class="table-responsive">
    <table class="table table-hover" id="ipTable">
      <b-thead>
        <tr v-if="filterable">
          <td colspan="100%">
            <vc-date-picker v-model="currRange" is-range>
              <template v-slot="{ inputValue, inputEvents, updateValue }">
                <div class="d-flex justify-content-center">
                  <div class="align-content-end d-grid mx-4">
                    <b-button-group>
                      <b-dropdown right variant="success" text="Quick Filters">
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilterTime(0, 'month'));
                            applyFilter();
                          "
                          >Current Month</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilterTime(1, 'month'));
                            applyFilter();
                          "
                          >Previous Month</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilter(3, 'months'));
                            applyFilter();
                          "
                          >Last 3 Months</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilter(6, 'months'));
                            applyFilter();
                          "
                          >Last 6 Months</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="
                            updateValue(quickFilter(1, 'year'));
                            applyFilter();
                          "
                          >Last 1 Year</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-button-group>
                  </div>
                  <div class="w-50">
                    Filter Records:
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text>
                          <i class="fa fa-calendar"></i
                        ></b-input-group-text>
                      </template>
                      <template #append>
                        <b-button
                          class="w-100"
                          variant="primary"
                          :disabled="!is_modified"
                          @click="applyFilter"
                          >Apply Filter</b-button
                        >
                      </template>

                      <b-form-input
                        readonly
                        class="bg-light"
                        :value="`${inputValue.start} - ${inputValue.end}`"
                        v-on="inputEvents.start"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div
                    v-if="showExportButton"
                    class="align-content-end d-grid mx-4"
                  >
                    <b-button class="ml-2" variant="primary" @click="exportData"
                      >Export
                    </b-button>
                  </div>
                </div>
              </template>
            </vc-date-picker>
          </td>
        </tr>
        <slot name="thead"></slot>
      </b-thead>
      <b-tbody>
        <slot name="tbody" v-if="$slots.tbody && !is_processing"></slot>
        <tr v-else>
          <td colspan="100%" class="text-center">Records not found</td>
        </tr>
      </b-tbody>
      <b-tfoot>
        <slot name="tfoot" v-if="$slots.tbody"></slot>
      </b-tfoot>
    </table>
  </div>
</template>

<script>
 //import * as XLSX from "xlsx";
  import ExcelJS from 'exceljs';
  import { saveAs } from 'file-saver';
  import moment from "moment";

export default {
  name: "Table",
  props: {
    // If filterable is set/passed, only then the table will show the filter options.
    filterable: {
      default: false,
      type: Boolean
    },
    // is_processing hides the records.
    is_processing: {
      default: false,
      type: Boolean
    },
    bus: {
      type: Object,
      default: () => {}
    },
    showExportButton: {
      default: false,
      type: Boolean
    },
    range: Object
  },
  data() {
    return {
      currRange: {
        start: this.$moment().startOf("month").toDate(),
        end: this.$moment().endOf("month").toDate()
      },
      is_modified: false, // It is used to indicate if the apply filter button should be enable or not.
      is_project_selected: false
    };
  },
  methods: {
    // This method emits the apply-filter event, which can be handled in the parent component.
    // It also gives start and end date to the parent component
    // Here we will disable the "Apply Filter button" once clicked.
    applyFilter() {
      if (this.is_project_selected) {
        console.log("project is selected: ", this.is_project_selected);
        this.$set(this, "is_project_selected", false);
      }
      this.formatRange();
      setTimeout(() => this.$set(this, "is_modified", false), 100);
      this.$emit("apply-filter", this.currRange);
    },
    getCurrencySymbol(currencyCode) {
      const currencySymbols = {
        USD: '$',  // US Dollar
        EUR: '€',  // Euro
        CAD: 'C$', // Canadian Dollar
        INR: '₹',  // Indian Rupee
        GBP: '£'   // British Pound
      };
      return currencySymbols[currencyCode] || ''; // Default to empty if not matched
    }, 
    async exportData() {
      const data = this.extractSlotData(this.$slots.tbody);

       // Extract the year from the start date
      const year = moment(this.currRange.start).year();

      const mappedData = data.map((row) => ({
        //Date: moment(row.column0).year(year).startOf('day').toDate(), // Ensure the date has the correct year and no time component
        Date: moment(row.column0).year(year).startOf('day').format('YYYY-MM-DD'), 
        Project: row.column1,
        Category: row.column2,  //expense type
        Currency: row.column3, //basically amount you payed
        Claimed: row.column4 //amount claimed after currency exchange 
      }));
      console.log("mappedData: ", mappedData);

      // Create a new workbook and add a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Expenses');

      // Set column widths
      worksheet.getColumn(1).width = 12; // Set width to 12 characters
      worksheet.getColumn(2).width = 25; // Set width to 25 characters
      worksheet.getColumn(3).width = 25; // Set width to 25 characters
      worksheet.getColumn(4).width = 12; // Set width to 12 characters
      worksheet.getColumn(5).width = 12; // Set width to 12 characters


      // Add headers
      const headers = ["Date", "Project", "Category", "Currency", "Claimed"];
      const headerRow = worksheet.addRow(headers);

      // Apply bold style to the first row
      headerRow.eachCell(cell => {
        cell.font = { bold: true, size: 12 };
         cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'dee2e6' } // Olive green accent 3, lighter 40%
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });



      // Add data
      mappedData.forEach(row => {
        const dataRow = worksheet.addRow(Object.values(row));
        dataRow.eachCell((cell, colNumber) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          // Set date format for the Date column
          if (colNumber === 1) {
            cell.value = new Date(cell.value);
            console.log(cell.value instanceof Date);
          }
          // Set currency format for the Claimed column
          // Set currency format for the Claimed column
          if (colNumber === 5) {
            const currencyMatch = cell.value.match(/\(([^)]+)\)\s*([\d.]+)/);
            if (currencyMatch) {
              const currencyCode = currencyMatch[1];
              const currencySymbol = this.getCurrencySymbol(currencyCode); // Use 'this' to call the method
              console.log("Currency symbol: ", currencySymbol);
              const amount = parseFloat(currencyMatch[2]);
              cell.value = amount;
              cell.numFmt = `${currencySymbol} #,##0.00`; // Simplified custom accounting format with currency symbol
              console.log("cell.numFmt: ", cell.numFmt);
              console.log("Currency code: ", currencyCode);
              console.log("numFmt ", cell.numFmt);
            }
          }
        });
      });
      console.log("MAPPED DATA: ", mappedData);

      // Format the date range for the file name
      const startDate = moment(this.currRange.start).format('MM-DD-YYYY');
      const endDate = moment(this.currRange.end).format('MM-DD-YYYY');
      const fileName = `Expenses-${startDate}-${endDate}.xlsx`;

      // Generate Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
    }, 
    extractSlotData(slot) {
      if (!slot) return [];
      return slot.map((vnode) => {
        const rowData = {};
        if (vnode.children) {
          vnode.children.forEach((child, index) => {
            if (child.tag === "td" && child.children && child.children[0]) {
              rowData[`column${index}`] = child.children[0].text;
            }
          });
        }
        return rowData;
      });
    },
    // quickFilter accepts: amount and time
    // amount: amount of time needs to go back to the past (1,2,3) etc. refer moment doc.,
    // time: unit of time (i.e. 'months', 'days') etc. refer moment doc.
    // and returns object with start and end properties
    quickFilter(amount, time) {
      return {
        start: this.$moment().subtract(amount, time).toDate(),
        end: new Date()
      };
    },

    // quickFilterTime accepts: amount and time. However, it starts the date from start of the month.
    // amount: amount of time needs to go back to the past (1,2,3) etc. refer moment doc.,
    // time: unit of time (i.e. 'months', 'days') etc. refer moment doc.
    // and returns object with start and end properties
    quickFilterTime(amount, time) {
      let _subtracted = this.$moment().subtract(amount, time);
      return {
        start: _subtracted.startOf(time).toDate(),
        end:
          amount == 0
            ? this.$moment().endOf(time).toDate()
            : _subtracted.endOf(time).toDate()
      };
    },

    // This method converts date into given formatted string.
    toString(date) {
      return this.$moment(date).format("MM/DD/YYYY");
    },

    // This method converts currRange objects toString().
    formatRange() {
      this.currRange.start = this.toString(this.currRange.start);
      this.currRange.end = this.toString(this.currRange.end);
    },

    formatBillingRange(billingCycle) {
      console.log("billingCycle is here:  ", billingCycle);
      if (billingCycle.length > 0) {
        this.$set(this, "is_project_selected", true);
      } else {
        this.$set(this, "is_project_selected", false);
      }
      if (billingCycle == '"Fixed Offset (26 to 25)"') {
        // set start date to 26th of previous month
        var start = this.$moment().subtract(1, "months").date(26).toDate();
        // set end date to 25th of current month
        var end = this.$moment().date(25).toDate();

        start = this.toString(start);
        end = this.toString(end);

        this.$set(this, "currRange", { start, end });
      } else if (billingCycle.length === 0) {
        this.$set(this, "currRange", {
          start: this.$moment().startOf("month").toDate(),
          end: this.$moment().endOf("month").toDate()
        });
      }
      // console.log("currRange is here:  ", this.currRange);
    },

    // This method compares 2 date object and checks if the date has been modified or not.
    haveDatesModified(_new, _old) {
      if (_new && !_old) {
        return true;
      }
      return !(
        this.toString(_new.start) === this.toString(_old.start) &&
        this.toString(_new.end) === this.toString(_old.end)
      );
    }
  },
  mounted() {
    // applyingFilter when the table is mounted,
    // because we want to show the data limited to last 30 days by default.
    this.applyFilter();
  },
  watch: {
    // watching currRange object and calling haveDatesModified() to check changes,
    // If the date is modified then we will enable the "Apply Filter" button.
    range(newRange) {
      if (newRange) this.currRange = newRange;
    },
    currRange: {
      handler(_new, _old) {
        if (this.haveDatesModified(_new, _old)) {
          this.is_modified = true;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.d-grid {
  display: grid;
}
</style>
