import TimeSheetData from "./TimeSheetData";

export default class TimeSheetBatch {
  /**
   *
   * @param {string} projectId the id of the project being used
   * @param {string} activity freeform description of the activity
   * @param {an array of objects of the form (date, hours)} time
   */
  constructor(projectId = "", activity = "", time = [], subProjectId = "") {
    this.projectId = projectId;
    this.subProjectId = subProjectId;
    this.activity = activity;
    this.time = time;
    this.isEdited = false;
  }

  /**
   * Adds a new time object unto the time
   * @param {Date} date the date we are adding.
   */
  addTime(date) {
    this.time.push({ date: date, hours: null });
    this.time.sort((a, b) => a.date > b.date);
  }

  /**
   *
   * @param {int} index the date to delete the time at.
   */
  deleteTime(date) {
    if (date instanceof Date) {
      const deleteIndex = this.time.findIndex(
        (t) => t.date.getTime() === date.getTime()
      );
      if (deleteIndex >= 0) {
        this.time.splice(deleteIndex, 1);
      }
    }
  }

  /**
   * Converts the data in the batch into
   * the appropriate set of timesheets.
   */
  toTimeSheets() {
    let timeSheets = [];
    this.time.forEach((timesheet) => {
      timeSheets.push(
        new TimeSheetData(
          -1,
          timesheet.date,
          this.activity,
          timesheet.hours,
          timesheet.ot_hours,
          this.projectId,
          this.subProjectId,
          false,
          null,
          "",
          timesheet.comment
        )
      );
    });

    return timeSheets;
  }
}
